import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0_dgbte4nawart77kikpwnq7qcxe/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0_dgbte4nawart77kikpwnq7qcxe/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0_dgbte4nawart77kikpwnq7qcxe/node_modules/@mantine/core/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+carousel@7.13.2_@mantine+core@7.13.2_@mantine+hooks@7.13.2_embla-carousel-react@8.3._2juolwddukrlk2cueswb4ofpue/node_modules/@mantine/carousel/styles.layer.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/app/node_modules/.pnpm/@mantine+notifications@7.13.2_@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-_tsldhogpqcyz6bq6eeh2vbiova/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/app/node_modules/.pnpm/@mantine+notifications@7.13.2_@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-_tsldhogpqcyz6bq6eeh2vbiova/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LexicalEditorTheme"] */ "/app/node_modules/.pnpm/@payloadcms+richtext-lexical@3.0.0-beta.111_@faceless-ui+modal@3.0.0-beta.2_@faceless-ui+scro_j4gudttqoncmnhavfbobwrlyse/node_modules/@payloadcms/richtext-lexical/dist/lexical/theme/EditorTheme.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(app)/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+notifications@7.13.2_@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-_tsldhogpqcyz6bq6eeh2vbiova/node_modules/@mantine/notifications/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.181_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0-rc-2d16326d-20240930/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"cyrillic\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.181_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0-rc-2d16326d-20240930/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Oswald\",\"arguments\":[{\"subsets\":[\"cyrillic\"],\"variable\":\"--font-oswald\",\"display\":\"swap\"}],\"variableName\":\"oswald\"}");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/app/src/styles/theme.tsx");
